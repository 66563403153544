import { useEffect, useRef, useCallback } from "react";

const useTimer = () => {
  const timer = useRef();
  const fn = useCallback((callback, timeout = 0) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      callback();
    }, timeout);
  }, []);

  const clearTimeoutHandler = () => {
    return clearTimeout(timer);
  };

  useEffect(() => {
    return clearTimeoutHandler();
  }, []);

  return { fn, clearTimeoutHandler };
};

export default useTimer;