import ENV from "./config.env";

// links
export function getScreenshotPath(screenshotName) {
  return `screenshots/${screenshotName}.png`;
}

// arrays & objs
export function objectEquals(x, y) {
  return (x && y && typeof x === 'object' && typeof y === 'object') ?
    (Object.keys(x).length === Object.keys(y).length) &&
      Object.keys(x).reduce(function(isEqual, key) {
        return isEqual && objectEquals(x[key], y[key]);
      }, true) : (x === y);
}
export function arrayIntersperse(arr, getSeparator) {
  if(!arr?.length)
    return arr;
  const lastElt = arr.pop();
  const out = [];
  arr.forEach(elt=>{
    out.push(elt);
    out.push(getSeparator());
  });
  out.push(lastElt);
  return out;
}
export function arrayDedupe(arr, equalsFunc) {
  const out = [];
  if(!equalsFunc)
    equalsFunc = (a,b)=>a===b;
  arr.forEach(elt=>{
    if(out.find(outElt=>equalsFunc(outElt, elt))===undefined)
      out.push(elt);
  });
  return out;
}
export function arrayExtract(arr, extractFunc) {
  //console.log('arrayExtract', arr);
  const extracted = [];
  for(var i=0; i<arr.length; ++i) {
    const val = arr[i];
    if(extractFunc(val)) {
      extracted.push(val);
      arr.splice(i,1);
      i--;
    }
  }
  return extracted;
}

// streaks
export function toIsoString(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
        return (num < 10 ? '0' : '') + num;
    };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60);
}

// rendering
export function capitalizeFirstLetter(string) {
  if(!string)
    return '';
  if(string.slice(0,5)==='(to) ')
    return string.slice(0,5) + string.charAt(5).toUpperCase() + string.slice(6);
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function unicodeSpaces(num=1) {
  return '\u00A0'.repeat(num);
}
export const disabledLinkStyles = {
  pointerEvents:'none',
  textDecoration:'inherit',
  color:'inherit',
};

// other
export function assert(isOk, msg) {
  if(!isOk)
    throw new Error(msg);
}

// payments
export function getSubscriptionLink(user) {
  const baseUrl = (ENV==='dev') ? 'https://buy.stripe.com/test_fZe29Z8vs7Gu7VSbII' : 'https://buy.stripe.com/14k4gU5ZP2sVe2c9AA';
  return `${baseUrl}?prefilled_email=${user.email}&client_reference_id=${user.id}`;
}