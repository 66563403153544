import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { capitalizeFirstLetter, arrayDedupe, arrayExtract, assert, objectEquals, getSubscriptionLink } from '../common/utility';

import UserDataService from "../services/user.service";
import * as demoData from "../common/demoData.json";

import Loading from "./loading.component";
import Combobox from "./combobox.component";

import useAuth from "../hooks/auth.hook";
import useData from "../hooks/data.hook";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

var map;
var mapId = 0;
var markers = [];
var AdvancedMarkerElement, PinElement, LatLngBounds, Geometry;
var places=[], place=null;
var lastScrollPos, needScrollFix = false;
var lastHighlightedPlace;
var polygons = [];
var newRegionPolyline = null;
var newRegionMarkers = [];
var newRegionName = null;
var regionDeleteMode = false;
var getMapDataCurrent = null;

export default function Dashboard(props) {
  const { authed, userId } = useAuth();
  const { user } = useData('user', {authed});
  var { mapData, processResult } = useData('mapData', { authed });
  console.log('made new dashboard', !!mapData);

  const mapRef = useRef();
  const newRegionNameInput = useRef();
  const newRegionNameBox = useRef();
  const polygonHoverRef = useRef();
  const [ searchText, setSearchText ] = useState('');
  const [ showSearchHints, setShowSearchHints ] = useState(false);
  const [ currentPlace, setCurrentPlace ] = useState(null);
  
  // track current data value
  getMapDataCurrent = () => {
    return mapData;
  };
  function setMapDataToCurrent() {
    mapData = getMapDataCurrent();
  }
  
  // handle demo mode
  const demoMode = props.mode==='demo';
  if(demoMode) {
    mapData = demoData;
  }
    
  // place helpers
  function getPlaceTypesDisplay(place) {
    return place.types.filter(t=>!['point_of_interest', 'establishment', 'tourist_attraction'].includes(t) && !t.includes('locality')).map(t=>capitalizeFirstLetter(t.replaceAll('_', ' '))).join(', ');
  }
  function getPlacePrimaryTypeDisplay(place) {
    var text = place.primaryType;
    if(!text) {
      text = '';
      if(place.types.includes('political'))
        text = "City";
    }
    return text;
  }
  function getPlaceTextTags(place) {
    return place.tags?.filter(t=>t.type==='text').map(t=>({
      name:t.name,
      userId:t.userId,
    })) ?? [];
  }
  function updatePlaceRegions() {
    mapData.places.forEach(place=>{
      place.regions = [];
      mapData.regions.forEach(region=>{
        const polygon = polygons.find(p=>p.regionId===region.id);
        // eslint-disable-next-line
        if(polygon && Geometry.poly.containsLocation(new google.maps.LatLng(place.gpsCoord.coordinates[0], place.gpsCoord.coordinates[1]), polygon)) {
          place.regions.push(region);
        }
      });
    });
  }
  function getPlaceRegionNames(place) {
    return place.regions?.map(r=>r.name.toLowerCase()) ?? [];
  }
  function getPlaceVisits(place) {
    return place.tags?.filter(t=>t.type==='visitTimestamp').map(t=>({
      time:t.time,
      userId:t.userId,
    })) ?? [];
  }
  function getPlaceLL(place) {
    return {
      lat:place.gpsCoord.coordinates[0],
      lng:place.gpsCoord.coordinates[1],
    };
  }
  function getPlaceListNames(place) {
    return place.lists.map(listId=>mapData.lists.find(l=>l.id===listId).name);
  }
    
  function addPolygon(region) {
    assert(map);
    // eslint-disable-next-line
    const polygon = new google.maps.Polygon({
      map: map,
      paths: geometryToPath(region.geometry),
      strokeColor: '#F00',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#F00',
      fillOpacity: 0.35,
      geodesic: true,
      gmpClickable: true,
    });
    polygon.addListener("click", () => {
      if(regionDeleteMode) {
        deleteRegion(region);
      }
    });
    function positionHover(e) {
      const x = e.domEvent.offsetX+5;
      const y = e.domEvent.offsetY+20;
      polygonHoverRef.current.style.top = `${y}px`;
      polygonHoverRef.current.style.left = `${x}px`;
    }
    polygon.addListener("mousemove", e => {
      positionHover(e);
    });
    polygon.addListener("mouseover", e => {
      polygonHoverRef.current.style.display = 'block';
      polygonHoverRef.current.innerHTML = region.name;
      positionHover(e);
    });
    polygon.addListener("mouseout", e => {
      polygonHoverRef.current.style.display = 'none';
      positionHover(e);
    });
    polygon.regionId = region.id;
    polygons.push(polygon);
    return polygon;
  }
  function trySetPolygons() {
    // eslint-disable-next-line
    if(!google.maps.Polygon || !map)
      return false;
    
    // clear polys
    polygons.forEach(polygon=>{
      polygon.setMap(null);
    });
    polygons = [];
    
    // create polys
    mapData.regions.forEach(region=>{
      addPolygon(region);
    });
    return true;
  }
  function setMapMarkers() {
    var needBoundsRefit = false;
    
    // check for a new map
    if(markers.length && markers[0].map.id!==map.id) {
      markers.forEach(marker=>{
        marker.map = null;
      });
      markers = [];
      needBoundsRefit = true;
    }
    
    // prep for marker check
    markers.forEach(marker=>{
      delete marker.keep;
    });

    // create new markers
    const bounds = new LatLngBounds();
    places.forEach(place=>{
      var marker = markers.find(m=>m.placeId===place.id);
      const position = getPlaceLL(place);
      if(!!marker) {
        marker.keep = true;
      }
      else {
        marker = new AdvancedMarkerElement({
          map: map,
          position,
          title: place.name,
          gmpClickable: true,
        });
        marker.addListener("click", () => {
          selectPlaceInner(place.id);
        });
        
        marker.keep = true;
        marker.placeId = place.id;
        markers.push(marker);
        needBoundsRefit = true;
      }
      bounds.extend(position);
    });
        
    // remove unused markers
    [...markers].forEach(marker=>{
      if(!marker.keep) {
        marker.map = null;
        arrayExtract(markers, m=>m.placeId===marker.placeId);
        needBoundsRefit = true;
      }
    });
  
    // adjust display to show new markers
    if(needBoundsRefit && mapData?.places.length) {
      map.fitBounds(bounds);
      //window.scrollTo({top:0, left:0, behavior: "instant"});
    }
  }
  
  // Initialize and add the map
  useEffect(() => {
    async function initMap() {
      // eslint-disable-next-line
      Geometry = await google.maps.importLibrary("geometry");
      // eslint-disable-next-line
      const { Map } = await google.maps.importLibrary("maps");
      var libraries;
      // eslint-disable-next-line
      libraries = await google.maps.importLibrary("marker");
      AdvancedMarkerElement = libraries.AdvancedMarkerElement;
      PinElement = libraries.PinElement;
      // eslint-disable-next-line
      libraries = await google.maps.importLibrary("core");
      LatLngBounds = libraries.LatLngBounds;

      // The map
      map = new Map(mapRef.current, {
        zoom: 4,
        center:{ lat: 39.2, lng: -97 },
        mapId: "DEMO_MAP_ID",
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        rotateControl: false,
        zoomControlOptions: {
          // eslint-disable-next-line
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      });
      map.id = mapId++;
      mapControls.forEach(control=>{
        // eslint-disable-next-line
        addMapControl(google.maps.ControlPosition.RIGHT_CENTER, control);
      });

      map.addListener("click", mapClick);
      console.log('loaded new map', mapId, !!mapData);
      
      // in case the map data loads first, set the markers here. tends to happen when switching back to map tab from other tabs
      if(mapData) {
        setMapMarkers();
        trySetPolygons();
      }
    }
    
    const isMapLoaded = !!mapRef.current?.querySelector('.gm-style');
    console.log('try load new map', !!user, isMapLoaded);
    if(!isMapLoaded && ((authed && user?.monthCost<500 && !!user?.subscription) || demoMode)) {
      initMap();

      if(!demoMode) {
        UserDataService.incMeterMapRefresh()
        .then(result => {
          // ignore result
        });
      }
    }
  }); // run every render
  
  // save scroll position when user switches between place list and place card
  useEffect(() => {
    if(needScrollFix) {
      window.scrollTo({top:lastScrollPos, left:0, behavior: "instant"});
      lastScrollPos = null;
      needScrollFix = false;
    }
  }); // run every render
  
  // custom map controls
  const mapControls = [
    {
      iconName:'pencil-square',
      iconAlt:'Create new map region',
      func:regionBeginDraw,
      topAdjustment:-18,
      type:'create',
    },
    {
      iconName:'x-square',
      iconAlt:'Delete a map region',
      func:regionDelete,
      topAdjustment:24,
      type:'delete',
    },
  ];
  function addMapControl(position, control) {
    const controlButton = document.createElement('button');

    // Set CSS for the control
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '16px';
    controlButton.style.lineHeight = '38px';
    controlButton.style.margin = '8px 0 22px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';
    controlButton.style.position = 'absolute';
    controlButton.style.right = '10px';
    controlButton.style.top = `${control.topAdjustment}px`;
    controlButton.style.width = '40px';

    controlButton.innerHTML = `<i class="bi bi-${control.iconName}" style="zoom:1.4"></i>`;
    controlButton.title = control.iconAlt;
    controlButton.type = 'button';

    // Setup the click event listeners
    controlButton.addEventListener('click', control.func);
    
    // Create the DIV to hold the control
    const controlDiv = document.createElement('div');
    // Append the control to the div
    controlDiv.appendChild(controlButton);
    controlDiv.type = control.type;

    map.controls[position].push(controlDiv);
    return controlDiv;
  }
  function getMapControlElt(type) {
    // eslint-disable-next-line
    return map.controls[google.maps.ControlPosition.RIGHT_CENTER].getArray().find(c=>c.type===type);
  }
  function setMapControlActive(type, isActive) {
    const control = getMapControlElt(type);
    control.style.filter = isActive ? 'invert(1)': '';
  }
  
  // create region
  function regionBeginDraw() {
    setRegionDeleteMode(false);
    clearNewRegionPolyline();
    nameBoxShow(true);
    setMapControlActive('create', true);
  }
  function nameBoxShow(show) {
    const elt = newRegionNameBox.current;
    elt.classList.remove(show?'hide':'show');
    elt.classList.add(show?'show':'hide');
    elt.style.display = show?'block':'none';
  }
  function regionNameDone() {
    newRegionName = newRegionNameInput.current.value;
    newRegionNameInput.current.value = "";
    nameBoxShow(false);

    // eslint-disable-next-line
    newRegionPolyline = new google.maps.Polyline({
      strokeColor: "#F00",
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    newRegionPolyline.setMap(map);
  }
  function mapClick(e) {
    setMapDataToCurrent();
    console.log('mapClick', mapData);
    if(!!newRegionPolyline) {
      const path = newRegionPolyline.getPath();
      const eventLL = e.latLng;
      const eventP = pixelFromLatLng(eventLL);
      var lastProximityClose = false;
      for(var i=0; i<path.length && !lastProximityClose; ++i) {
        const pathPointP = pixelFromLatLng(path.getArray()[i]);
        const proximity = Math.hypot(eventP.x-pathPointP.x, eventP.y-pathPointP.y);
        lastProximityClose = proximity<20;
      }
      if(lastProximityClose) {
        finishRegion(path);
        clearNewRegionPolyline();
      }
      else {
        path.push(eventLL);
        function getNewRegionPointMarker() {
          const div = document.createElement('div');
          div.style.display = 'block';
          div.style.width = '6px';
          div.style.height = '6px';
          div.style.borderRadius = '50%';
          div.style.backgroundColor = 'red';
          div.style.transform = 'translate3d(0,50%,0)';
          return div;
        }
        newRegionMarkers.push(new AdvancedMarkerElement({
          map,
          position:eventLL,
          content:getNewRegionPointMarker(),
        }));
      }
    }
    else {
      // deletes are handled in the polygon click event
      setRegionDeleteMode(false);
    }
  }
  function finishRegion(path) {
    const regionName = newRegionName;
    const region = {
      id:null,
      name:regionName,
      geometry:pathToGeometry(path),
    };
    const polygon = addPolygon(region);
    mapData.regions.push(region);
    updatePlaceRegions();
    processResult(getInstantResult());
    clearNewRegionPolyline();
    
    if(!demoMode) {
      UserDataService.addRegion(regionName, path.getArray())
      .then(result => {
        polygon.regionId = result.result.newRegion.id;
        processResult(result);
        //showAlert('Region deleted');
      });
    }
  }
  function clearNewRegionPolyline() {
    if(newRegionPolyline) {
      newRegionPolyline.setMap(null);
      newRegionPolyline = null;
      newRegionName = null;
      newRegionMarkers.forEach(c=>{
        c.map = null;
      });
      newRegionMarkers = [];
      setMapControlActive('create', false);
    }
  }
  function pixelFromLatLng(latLng) {
    var projection = map.getProjection();
    var bounds = map.getBounds();
    var topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
    var bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
    var scale = Math.pow(2, map.getZoom());
    var worldPoint = projection.fromLatLngToPoint(latLng);
    return {
      x:Math.floor((worldPoint.x - bottomLeft.x) * scale),
      y:Math.floor((worldPoint.y - topRight.y) * scale)
    };
  }
  function geometryToPath(geometry) {
    return geometry.coordinates[0].map(pt=>({
      lat:pt[0],
      lng:pt[1]
    }));
  }
  function pathToGeometry(path) {
    return {
      type:'Polygon',
      coordinates:[
        path.getArray().map(pt=>([
          pt.lat(),
          pt.lng()
        ]))
      ]
    };
  }
  
  // delete region
  function regionDelete() {
    clearNewRegionPolyline();
    nameBoxShow(false);
    setMapControlActive('create', false);
    setRegionDeleteMode(!regionDeleteMode);
  }
  function deleteRegion(region) {
    setRegionDeleteMode(false);
    setMapDataToCurrent();
    
    var polygon;
    if(!region.id) {
      region.id = findPolygonFromRegionGeometry(region).regionId;
    }
    assert(region.id);
    polygon = arrayExtract(polygons, p=>p.regionId===region.id)[0];
    polygon.setMap(null);
    arrayExtract(mapData.regions, r=>r.id===region.id);
    processResult(getInstantResult());
    updatePlaceRegions();
    
    if(!demoMode) {
      UserDataService.deleteRegion(region.id)
      .then(result => {
        processResult(result);
        //showAlert('Region deleted');
      });
    }
  }
  function setRegionDeleteMode(newVal) {
    regionDeleteMode = newVal;
    setMapControlActive('delete', newVal);
  }
  function findPolygonFromRegionGeometry(region) {
    return polygons.find(p=>objectEquals(pathToGeometry(p.getPath()), region.geometry));
  }
    
  // place
  function selectPlaceInner(id) {
    setCurrentPlace(id);
    lastScrollPos = window.scrollY;
  }
  function selectPlace(id) {
    return ()=>{
      selectPlaceInner(id);
    };
  }
  function clearLastHighlightedPlace() {
    if(lastHighlightedPlace) {
      const lastMarker = markers.find(m=>m.placeId===lastHighlightedPlace);
      lastHighlightedPlace = null;
      if(lastMarker)
        lastMarker.content = (new PinElement()).element;
    }
  }
  function hoverPlace(id) {
    return ()=>{
      clearLastHighlightedPlace();
      lastHighlightedPlace = id;
      const marker = markers.find(m=>m.placeId===id);
      const lightBlue = '#4051D8';
      const darkBlue = '#1F29A4';
      marker.content = (new PinElement({
        background:lightBlue,
        glyphColor:darkBlue,
        borderColor:darkBlue,
      })).element;
    };
  }
  function unHoverPlace() {
    clearLastHighlightedPlace();
  }
  function placeBack() {
    setCurrentPlace(null);
    needScrollFix = true;
  }
  function openTabs() {
    places.forEach(place=>{
      window.open(place.url, '_blank');
    });
  }

  // loading screen
  if(!authed && !demoMode) {
    return (<Loading />);
  }
  
  // subscription screen
  if(authed && !!user && !user.subscription) {
    return (
      <div class="text-center mt-5">
        <div class="fs-3">
          You must subscribe to use Map Tools!
        </div>
        <div class="mt-4 mx-auto" style={{display:'inline-block'}}>
          <Link to={getSubscriptionLink(user)}>
            <button class="btn btn-primary signup-btn w-100">Subscribe to Map Tools</button>
          </Link>
        </div>
      </div>
    );
  }

  // search
  function handleSearchChange(e) {
    setSearchText(e.target.value);
  }
  function handleSearchClear() {
    setSearchText('');
  }

  if(mapData) {
    // create polygons & tag places with regions
    if(!mapData.firstRegionUpdate) {
      console.log('got new map data');
      updatePlaceRegions();      
      mapData.firstRegionUpdate = true;
      trySetPolygons();
    }
    
    // do search to filter mapData.places down to places
    if(!searchText) {
      places = mapData.places;
    }
    else {
      const searches = searchText.split(' ').map(searchWord=>{
        function newSearch(type, data, isExcluded) {
          assert(type!==undefined && data!==undefined && isExcluded!==undefined);
          return { type, data, isExcluded };
        }
        var isMinus = searchWord.charAt(0)==='-';
        if(isMinus)
          searchWord = searchWord.slice(1);
        searchWord = searchWord.replaceAll('-', ' ');
        var isColon = searchWord.split(':').length===2 && searchWord.split('>').length===1;
        var isGT = searchWord.split('>').length===2 && searchWord.split(':').length===1;
        searchWord = searchWord.toLowerCase();
        
        var search;
        if(isColon) {
          const [type, data] = searchWord.split(":");
          search = null;
          switch(type) {
          case 'list':
          case 'name':
          case 'type':
          case 'city':
          case 'region':
          case 'tag':
            return newSearch(type, data, isMinus);
          default:
            // ignore invalid search types
          }
          return search;
        }
        else if(isGT) {
          const [type, data] = searchWord.split(">");
          search = null;
          switch(type) {
          case 'open':
            if(data.length===4 && parseInt(data.slice(0,2))<24 && parseInt(data.slice(2))<60) {
              return newSearch(type, parseInt(data), isMinus);
            }
            break;
          case 'rating':
            return newSearch(type, parseFloat(data), isMinus);
          case 'numratings':
            return newSearch(type, parseInt(data), isMinus);
          case 'visit':
            return newSearch(type, parseInt(data), isMinus);
          default:
          }
          return search;
        }
        else {
          return newSearch('text', searchWord, isMinus);
        }
      });
      places = mapData.places.filter(place=>{
        var ok = true;
        var strings = [];
        strings.push(place.name.toLowerCase());
        strings.push(getPlaceTypesDisplay(place).toLowerCase());
        strings.push(getPlacePrimaryTypeDisplay(place).toLowerCase());
        const listNames = getPlaceListNames(place).map(n=>n.toLowerCase());
        strings = strings.concat(listNames);
        strings = strings.concat(getPlaceTextTags(place).map(t=>t.name));
        strings = strings.concat(getPlaceRegionNames(place));
        strings.push(place.neighborhood?.toLowerCase());
        strings.push(place.city?.toLowerCase());
        strings.push(place.state?.toLowerCase());
        strings.push(place.country?.toLowerCase());
        strings = strings.filter(s=>!!s);
        for(var i=0; i<searches.length && ok; ++i) {
          const search = searches[i];
          if(!search)
            continue;
          switch(search.type) {
          case 'text':
            ok = !!strings.find(s=>s.includes(search.data));
            break;
          case 'list':
            ok = !!listNames.find(ln=>ln.includes(search.data));
            break;
          case 'name':
            ok = place.name.toLowerCase().includes(search.data);
            break;
          case 'city':
            ok = place.city?.toLowerCase().includes(search.data);
            break;
          case 'type':
            ok = getPlacePrimaryTypeDisplay(place).toLowerCase().includes(search.data);
            break;
          case 'region':
            ok = getPlaceRegionNames(place).find(n=>n.includes(search.data));
            break;
          case 'tag':
            ok = !!getPlaceTextTags(place).map(t=>t.name).find(t=>t.includes(search.data));
            break;
          case 'rating':
            ok = place.rating>=search.data;
            break;
          case 'numratings':
            ok = place.numRatings>=search.data;
            break;
          case 'visit':
            const lastVisit = Math.max(...getPlaceVisits(place).map(v=>v.time));
            ok = !lastVisit || (Date.now()-1000*3600*24*search.data)>lastVisit;
            break;
          case 'open':
            const day = (new Date()).getDay();
            const openHours = place.openTimes[day];
            if(!openHours) {
              ok = true;
            }
            else if(openHours.length===2) {
              const open = parseInt(openHours[0].replace(':', ''));
              const close = parseInt(openHours[1].replace(':', ''));
              if(open<close) {
                ok = search.data>=open && search.data<=close;
              }
              else {
                ok = search.data>=open || search.data<=close;
              }
            } 
            else {
              ok = false;
            }
            break;
          default:
            // ignore invalid search types
          }
          if(search.isExcluded)
            ok = !ok;
        }
        return ok;
      });
      //console.log('built place list', map, places);
    }
    
    // update map markers based on search results
    if(map) {
      setMapMarkers();
    }
    
    // set place if necessary
    if(currentPlace) {
      place = mapData.places.find(p=>p.id===currentPlace);
    }
    else {
      place = null;
    }
  }
  
  // search hints
  function toggleSearchHints() {
    setShowSearchHints(!showSearchHints);
  }

  // tags
  var uniqueTags = [];
  if(mapData) {
    mapData.places.forEach(p=>{
      if(p.tags)
        uniqueTags = uniqueTags.concat(getPlaceTextTags(p).map(t=>t.name));
    });
    uniqueTags = arrayDedupe(uniqueTags);
  }
  function getInstantResult() {
    return {
      result:{
        newMapData:mapData
      }
    };
  }
  function addTag(name) {
    // make instant change
    place.tags ??= [];
    place.tags.push({
      userId,
      type:'text',
      name
    });
    processResult(getInstantResult());
    
    // send change to server
    if(!demoMode) {
      UserDataService.addTag(currentPlace, 'text', name)
      .then(result => {
        processResult(result);
        //showAlert('Tag added');
      });
    }
  }
  function deleteTag(tag) {
    return ()=>{
      // make instant change
      arrayExtract(place.tags, t=>t.userId===userId && t.type==='text' && t.name===tag.name);
      processResult(getInstantResult());
    
      // send change to server
      if(!demoMode) {
        UserDataService.deleteTag(currentPlace, 'text', tag.name)
        .then(result => {
          processResult(result);
          //showAlert('Tag deleted');
        });
      }
    };
  }
  function getUserNameTag(userId) {
    return <span class="fw-light opacity-50">({mapData.otherUsers.find(u=>u.id===userId).fullName.split(' ')[0]})</span>;
  }
  
  // visits
  function logVisit(visitType) {
    return () => {
      var timestamp = Date.now();
      switch(visitType) {
      case '-1w':
        timestamp -= 1000*3600*24*7;
        break;
      case '-1m':
        timestamp -= 1000*3600*24*365.25/12;
        break;
      case '-1q':
        timestamp -= 1000*3600*24*365.25/4;
        break;
      case '-1y':
        timestamp -= 1000*3600*24*365.25;
        break;
      default:
        // should only get here if we chose today
      }

      // make instant change
      place.tags ??= [];
      place.tags.push({
        userId,
        type:'visitTimestamp',
        time:timestamp
      });
      processResult(getInstantResult());
    
      // send change to server
      if(!demoMode) {
        UserDataService.addTag(currentPlace, 'visitTimestamp', timestamp.toString())
        .then(result => {
          processResult(result);
          //showAlert('Visit added');
        });
      }
    };
  }
  function deleteVisit(visit) {
    return ()=>{
      // make instant change
      arrayExtract(place.tags, t=>t.userId===userId && t.type==='visitTimestamp' && t.name===visit.time.toString());
      processResult(getInstantResult());
    
      // send change to server
      if(!demoMode) {
        UserDataService.deleteTag(currentPlace, 'visitTimestamp', visit.time.toString())
        .then(result => {
          processResult(result);
          //showAlert('Visit deleted');
        });
      }
    };
  }

  // render
  var dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return (
    <>
      <div class="row" style={{position:'relative'}}>
        <div class="col-4 pe-5 ps-4 pb-5">
          { !mapData ?
          <Loading />
          :
          <>
          { place ?
          <>
          <div class="row">
            <i class="bi bi-chevron-left icon-button fs-2 mt-3 mb-2" onClick={placeBack}></i>
          </div>
          <div class="row">
            <p>
              <div class="fs-2">
                <strong>{capitalizeFirstLetter(place.name)}</strong>
              </div>
              { !!place.primaryType &&
              <div class="text-muted">
                {getPlacePrimaryTypeDisplay(place)}
              </div>
              }
            </p>
            <p>
              <span>In Lists:</span> <strong>{getPlaceListNames(place).join(', ')}</strong>
            </p>
            <p>
              { !!place.neighborhood &&
              <div><span>Neighborhood:</span> <strong>{place.neighborhood.split(' , ')[0]}</strong></div>
              }
              <div><span>City:</span> <strong>{place.city?.split(' , ')[0]}</strong></div>
              <div><span>State:</span> <strong>{place.state?.split(' , ')[0]}</strong></div>
              <div><span>Country:</span> <strong>{place.country?.split(' , ')[0]}</strong></div>
            </p>
            { place.businessStatus && place.businessStatus!=='OPERATIONAL' &&
            <p class="text-danger">
              <strong>{capitalizeFirstLetter(place.businessStatus.toLowerCase())}</strong>
            </p>
            }
            { !!place.numRatings &&
            <p>
              <div>Rating: <strong>{place.rating}</strong></div>
              <div># of Reviews: <strong>{place.numRatings}</strong></div>
            </p>
            }
            { !!place.openTimes && !!place.openTimes.length && 
            <p>
              <div>Open times</div>
              { dayNames.map((dayName, i)=>
              <div class="text-muted">{dayName}: <strong>{place.openTimes[i].length!==2?'Closed':`${place.openTimes[i][0]} - ${place.openTimes[i][1]}`}</strong></div>
              )}
            </p>
            }
            <p>
              <Link to={place.url} target="_" class="btn btn-outline-primary">Visit on Google Maps</Link>
            </p>
            
            <hr/>
            
            <div class="mb-3">
              { getPlaceTextTags(place).map(tag=>
              <span class="badge rounded-pill text-bg-primary fs-5 me-2 mb-2">
                {capitalizeFirstLetter(tag.name)}&nbsp;
                { tag.userId===userId ?
                <i class="bi bi-x btn-icon" onClick={deleteTag(tag)} style={{cursor:'pointer'}}></i>
                :
                getUserNameTag(tag.userId)
                }
              </span>
              ) }
            </div>
            <Combobox tags={uniqueTags} onSubmit={addTag} />
              
            <hr class="mt-3" />
              
            <div class="mb-3">Your visits</div>
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-primary" onClick={logVisit('today')}>Today</button>
              <button type="button" class="btn btn-outline-primary" onClick={logVisit('-1w')}>-1 week</button>
              <button type="button" class="btn btn-outline-primary" onClick={logVisit('-1m')}>-1 month</button>
              <button type="button" class="btn btn-outline-primary" onClick={logVisit('-1q')}>-1 quarter</button>
              <button type="button" class="btn btn-outline-primary" onClick={logVisit('-1y')}>-1 year</button>
            </div>
            { !!getPlaceVisits(place).length && 
            <table class="table mb-0 w-100 h-100 mt-3" style={{tableLayout:'fixed'}}>
              <tbody>
                { getPlaceVisits(place).map((visit, index)=>(
                <tr class="mb-0" key={index}>
                  <td class={`fs-5 pe-2`}>{timeAgo.format(visit.time)} {visit.userId!==userId ? getUserNameTag(visit.userId) : ''}</td>
                  { visit.userId===userId &&
                  <td class="fs-5 text-danger">
                    <button type="button" class="btn-close" onClick={deleteVisit(visit)}></button>
                  </td>
                  }
                </tr>
                )) }
              </tbody>
            </table>
            }
              
          </div>
          </>
          :
          <>
            <div class="row py-4 sticky-top" style={{top:80, backgroundColor:'white'}}>
              <div class="d-flex">
                <div class="input-group flex-grow">
                  <input type="text" class="form-control" placeholder="Search..." onChange={handleSearchChange} value={searchText} />
                  { !!searchText &&
                  <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={handleSearchClear}>
                    <i class="bi bi-x btn-icon"></i>
                  </button>
                  }
                </div>
                <div class="ms-3">
                  <i class={`bi bi-question${showSearchHints?'-circle-fill':''} icon-button fs-4`} onClick={toggleSearchHints}></i>
                </div>
              </div>
              { showSearchHints &&
              <div>
                <div class="p-3 mt-2 bg-light rounded-2 text-muted">
                  Special filter terms:<br/>
                  <strong>name:X</strong> for place names<br/>
                  <strong>list:X</strong> for list names<br/>
                  <strong>type:X</strong> for place types<br/>
                  <strong>city:X</strong> for city names<br/>
                  <strong>region:X</strong> for region names<br/>
                  <strong>tag:X</strong> for tags<br/>
                  <strong>rating&gt;X.X</strong> for places with ratings &gt; X or X.X<br/>
                  <strong>numratings&gt;X</strong> for places with # of ratings &gt; X<br/>
                  <strong>open&gt;HHMM</strong> for places that are open today until or after HH:MM in military time<br/>
                  <strong>visit&gt;X</strong> for places you have NOT visited within the last X days<br/>
                  <br/>
                  Plain filter terms will match all fields. Terms with a "-" in front will be negated.<br/>
                  <br/>
                  Filters can be combined and partial matches are accepted, e.g. <strong>resta list:mex open&gt;2200 -tijuana</strong> would match restaurants in a list called "Mexico" that are open after 10pm and not located in Tijuana
                </div>
              </div>
              }
            </div>
            <div class="list-group">
              { places.map((place, i)=>
              <div class={`list-group-item list-group-item-action`} key={i} onClick={selectPlace(place.id)} onMouseEnter={hoverPlace(place.id)} onMouseLeave={unHoverPlace} style={{userSelect:'none', cursor:'pointer'}}>
                <div class="fs-5">{place.name}</div>
                <div class="fs-6 text-muted">{getPlacePrimaryTypeDisplay(place)}</div>
              </div>
              )}
            </div>
            { !!places.length &&
            <div class="mt-4 w-100 text-center">
              <button type="button" class="btn btn-outline-primary" onClick={openTabs}>Open all in new tabs</button>
            </div>
            }
            { !mapData?.places.length &&
            <div class="my-3 text-center">
              <div class="text-muted mb-3">
                You haven&apos;t imported your Google Maps saved lists yet!
              </div>
              <Link to="/lists"><button class="btn btn-primary signup-btn">Import lists</button></Link>
            </div>
            }
            { !!mapData?.places.length && !places.length && 
            <div class="text-center text-muted">
              No results
            </div>
            }
          </>
          }
          </>
          }
        </div>
        <div style={{position:'fixed', left:'34%', height:'100%', width:'66%', top:0, padding:0}}>
          <div style={{position:'relative', left:0, height:'100%', width:'100%', top:0}} ref={mapRef}>
          </div>
          <div style={{border:'1px solid #888', backgroundColor:'rgba(0,0,0,0.6)', display:'none', position:'absolute', padding:'1px 4px', color:'white', fontSize:11}} ref={polygonHoverRef}>
            Test text
          </div>
        </div>
        <div ref={newRegionNameBox} class="popover bs-popover-auto fade hide" role="tooltip" style={{position:'fixed', margin:0, maxWidth:500, width:400, display:'none', right:'58px', top:'calc(50% + 45px)'}} data-popper-placement="left">
          <div class="popover-arrow" style={{position:'absolute', transform:'translate3d(0px, 50%, 0px)', top:0}}></div>
          <div class="popover-body input-group">
            <input type="text" class="form-control" placeholder="Name..." ref={newRegionNameInput} />
            <button class="btn btn-primary signup-btn" onClick={regionNameDone}>Start drawing</button>
          </div>
        </div>
      </div>
    </>
  );
}