import http from "./http-common";

class LoginService {
  signin(timezone) {
    window.location = `/auth/login?timezone=${timezone}`;
  }
  check() {
    return http('/auth').get(`/check`);
  }
  logout() {
    return http('/auth').post(`/logout`);
  }
}

const loginService = new LoginService();
export default loginService;