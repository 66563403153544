import {isMobile} from 'react-device-detect';
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import '@popperjs/core/dist/esm/popper.js'
//import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css"; // custom boostrap css
import "bootstrap-icons/font/bootstrap-icons.css"
import 'bootstrap/dist/js/bootstrap.min.js'

import './App.css';

import Login from "./components/login.component";

import Loading from "./components/loading.component";
import Account from "./components/account.component";
import Dashboard from "./components/dashboard.component";
import Lists from "./components/lists.component";
import Feedback from "./components/feedback.component";

import useAuth from "./hooks/auth.hook";
import useData from "./hooks/data.hook";

import ENV from "./common/config.env";

if(ENV!=='dev') {
  console.log = ()=>{};
}

function navLink({link, text, isNav, classes, activeClasses, currLocation}) {
  classes ??= '';
  if(currLocation?.startsWith(link) && (link==='/'?link===currLocation:true)) { // HACK - will break if links start w same letters
    classes = activeClasses ?? 'me-3 fw-semibold text-bg-light rounded-3 border active ';
  }
  if(!isNav)
    return (<Link to={link}><button class={`btn ${classes?classes:''}`}>{text}</button></Link>);
  else
    return (<li class="nav-item"><Link to={link} class={`nav-link ${classes?classes:''}`} target={link.startsWith('http')?'_blank':''}>{text}</Link></li>);
}

function Header({headerRef}) {
  const { authed } = useAuth();
  const { user } = useData('user', {page:'header', authed});
  const location = useLocation();
  const currLocation = location.pathname;
  
  const onSigninPage = currLocation==='/signin' || currLocation==='/signup';
 
  // render
  const classes = 'me-3 text-bg-dark fw-light rounded-3 border ';
  return (
    <div class="row justify-content-center sticky-top" style={{zIndex:2000}} ref={headerRef}><div class="col-md-11">
      <header class="d-flex flex-wrap justify-content-center p-3 text-bg-dark rounded-bottom-3 navbar navbar-expand">
        <Link to={authed?'/dashboard':'/'} class={`d-flex align-items-center mb-3 mb-md-0 text-bg-dark text-decoration-none ${onSigninPage?'me-auto':''}`}>
          <img src="icons/android-chrome-192x192.png" alt="" class={`bi bi-translate fs-2`} style={{width:48}} />
          { !authed &&
          <span class="fs-4 ms-3 fw-medium">Map Tools</span>            
          }
        </Link>

        { !onSigninPage &&
        <>
          <ul class={`nav navbar-nav me-auto ms-4`}>
            { !authed &&
            <>
              <li class="nav-item">Demo Account</li>
            </>
            }
            { authed && !!user?.subscription &&
            <>
              {navLink({link:'/dashboard', text:'Dashboard', isNav:true, currLocation, classes})}
              {navLink({link:'/lists', text:'Lists', isNav:true, currLocation, classes})}
            </>
            }
          </ul>
          <ul class={`nav navbar-nav float-end`}>
            { authed ?
            <>
              {navLink({link:'/account', text:<span><i class="bi bi-person-circle me-1"></i> {user?.fullName}</span>, isNav:false, currLocation, classes:'btn-outline-light', activeClasses:'fw-semibold text-bg-light rounded-3 border active'})}
            </>
            :
            <>
              {navLink({link:'/signup', text:'Sign up', isNav:false, currLocation, classes:'btn-primary signup-btn me-3'})}
              {navLink({link:'/signin', text:'Log in', isNav:false, currLocation, classes:'text-bg-dark fw-light rounded-3 border'})}
            </>
            }
          </ul>
        </>
        }
      </header>
    </div></div>
  );
}

function Footer() {
  const { authed } = useAuth();
  const location = useLocation();
  const currLocation = location.pathname;
  const onDashboard = currLocation==='/dashboard';
  
  const classes = 'ms-3 text-muted rounded-3 border px-2 ';
  const activeClasses = 'ms-3 text-muted rounded-3 border px-2 active ';
  return (
    <div class="row justify-content-center mt-5" style={onDashboard?{position:'fixed', width:'100%', bottom:0, left:12}:{}}><div class="col-md-11">
      <footer class="d-flex flex-wrap justify-content-between align-items-center p-3 px-4 rounded-top-3 text-bg-light mt-4">
        <p class="col-md-4 mb-0 text-muted">&copy; Map Tools 2024. All rights reserved.</p>

        <dev class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-auto text-decoration-none">
          <Link to={authed?'/dashboard':'/'} class="text-muted">
            <img src="icons/favicon-32x32.png" alt="" style={{filter:`grayscale(1)`, opacity:0.8}} />
          </Link>
        </dev>          

        <ul class="nav col-md-4 justify-content-end">
          { !authed ?
          <>
          </>
          :
          <>
            {navLink({link:'/account', text:'Account', isNav:true, classes, activeClasses, currLocation})}
          </>
          }
        </ul>
      </footer>
    </div></div>
  );
}

function RequireAuth({children}) {
  const navigate = useNavigate();
  const { authed, check } = useAuth();
  const { setUser } = useData('user', {authed});

  if(authed===true) {
    return children;
  }
  else {
    console.log('auth check');
    check().then((user) => {
      if(!!user) {
        setUser(user);
        navigate("/dashboard");
      }
      else {
        navigate("/signin");
      }
    });
    return (<Loading />);
  }
}

export default function App() {
  const { authed } = useAuth();
  const location = useLocation();
  const currLocation = location.pathname;
  const onDashboard = currLocation==='/dashboard' || currLocation==='/';

  if(isMobile) {
    return (
      <div>Sorry, this app currently only works on desktop. It is optimized for Google Chrome.</div>
    );
  }

  return (
    <>
      <div>
        <Header />
        <div class={`container${onDashboard?'-fluid':''} mb-5`}>
          <div class="row justify-content-center">
            <div class="col-11">
              <Routes>
                <Route path="/" element={authed ? <Dashboard/> : <Dashboard mode={"demo"}/>} />
                <Route path="/signin" element={<Login/>} />
                <Route path="/signup" element={<Login mode={"signup"}/>} />

                <Route path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>} />
                <Route path="/lists" element={<RequireAuth><Lists/></RequireAuth>} />
                <Route path="/account" element={<RequireAuth><Account/></RequireAuth>} />
              </Routes>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      { authed &&
      <Feedback /> 
      }
    </>
  );
}

