import http from "./http-common";

class FeedbackService {
  async submitFeedback(data) {
    var out = await http().put(`/feedback/submit`, data);
    out = out.data;
    return out;
  }
}

const feedbackService = new FeedbackService();
export default feedbackService;