import LoginService from "../services/login.service";
import { useLocalStorage } from "@uidotdev/usehooks";

import { resetAllData } from "./data.hook";

var checkingAuth = false;
var authCheckers = [];

export default function useAuth() {
  const [token, setToken] = useLocalStorage('token', null);

  function getToken() {
    if(token==='null')
      return null;
    return token;
  }

  return {
    authed:!!getToken(),
    userId:getToken(),
    signin(timezone) {
      LoginService.signin(timezone);
    },
    check() {
      console.log('check login status');
      return new Promise((res) => {
        authCheckers.push(res);
        if(!checkingAuth) {
          checkingAuth = true;
          function finish(out) {
            checkingAuth = false;
            authCheckers.forEach(cb=>{
              cb(out);
            });
            authCheckers = [];
          }
          LoginService.check()
            .then(response => {
              console.log('login result', response);
              if(response.data===false) {
                setToken(null);
                finish(false);
              }
              else {
                const user = response.data;
                console.log('successful login', user);
                setToken(user.id);
                finish(user);
              }
            })
            .catch(e => {
              console.log(e);
              finish(false);
            });
        }
      });
    },
    logout() {
      return new Promise((res) => {
        LoginService.logout()
          .then(response => {
            console.log('successful logout');
            setToken(null);
            //window.localStorage.clear();
            resetAllData();
            res(true);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
  };
}
