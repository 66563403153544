import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

import useAuth from "../hooks/auth.hook";
import useTimer from "../hooks/timer.hook";

export default function Login(props) {
  const navigate = useNavigate();
  const { signin, authed } = useAuth();
  const timer = useTimer();
  const formRef = useRef();
  const [errorMsg, setErrorMsg] = useState('');
  
  useEffect(() => {
    if(authed) {
      navigate('/');
    }
  }); // run every render
    
  function showError(msg) {
    setErrorMsg(msg);
    timer.fn(() => {
      setErrorMsg('');
    }, 3000);
  }
  
  function handleLogin(e) {
    console.log('login start');
    e.preventDefault();
    function getClientTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    signin(getClientTimezone());
  }
  
  const signupMode = props.mode==='signup';

  function renderLoginForm() {
    return (
    <main class={`form-signin w-100 m-auto`} style={{'text-align':'center'}}>
    <form ref={formRef}>
      <h1 class="h3 mb-4 fw-normal text-muted">
        Welcome to Map Tools!
      </h1>

      <button class="btn btn-lg btn-primary" onClick={handleLogin}>
        <i class="bi bi-google me-3"></i> Sign {signupMode?'up':'in'} with Google
      </button>
    </form>
    </main>
    );
  }

  return (
    <>
      { !!errorMsg &&
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <i class="bi bi-exclamation-triangle-fill me-2"></i>
        <div>{errorMsg}</div>
      </div>
      }
      { signupMode ?
      <div class="row mt-5">
        <div class="col-6 position-relative">
          <div class="position-absolute w-100 m-auto" style={{top:'50%', transform:'translateY(-50%)'}}>
            { renderLoginForm() }
          </div>
        </div>
        <div class="col-6 px-5">
          <div class="card rounded-3 shadow-sm text-center justify-content-center">
            <div class="card-header py-3 text-bg-primary border-primary">
              <h4 class="my-0 fw-normal">Pro</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">$10<small class="text-muted fw-light">/mo</small></h1>
              <div class="text-center">
                <ul class="list-unstyled mt-3 mb-4 text-start" style={{display:'inline-block'}}>
                  <li>Unlimited Google Maps saved lists</li>
                  <li>+200 saved places per month</li>
                  <li>Instant search, pro filters</li>
                  <li>Unlimited tags, visits, and regions</li>
                  <li>Dedicated support from founder</li>
                  <li>Custom feature requests</li>
                  <li>...and more!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <div class="mt-5">
        { renderLoginForm() }
      </div>
      }
    </>
  );
}